<template>
  <article class="peer-information-container">
    <div class="frame">
      <header class="peer-information-header">
        <div class="logo-id">
          <div class="logo-group">
            <div class="logo-t">
              <img src="../img/JCDClogo.png" alt="" />
            </div>
            <div class="logo-s">
              <img src="../img/CHEClogo.png" alt="" />
            </div>
          </div>
          <div class="ID">ID: {{ data.code ? data.code : "-" }}</div>
        </div>
      </header>
      <div class="passengers-details">
        <img src="../img/leftLine.png" alt="" />
        <span class="desc">Visitors Details</span>
        <img src="../img/rightLine.png" alt="" />
      </div>
      <div class="peer-information-main">
        <table border="border: 1px solid #A47C43;" cellspacing="0">
          <thead>
            <tr>
              <th>No.</th>
              <th>Visitor's Full Name</th>
              <th>Identification Number</th>
              <th>Mobile Number</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, indexr) in tableData" :key="indexr">
              <td v-for="(column, indexc) in row" :key="indexc">
                <div class="table-item">{{ column.value }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="change-page">
        <div class="last-page" @click="getlastPage">上一页</div>
        <div class="next-page" @click="getNextPage">下一页</div>
      </div>
      <div class="photo-ID">
        <img v-if="data.qrcode" :src="data.qrcode" alt="" />
      </div>
    </div>
  </article>
</template>
<script>
import { dataInterface } from '@/apis/data';

export default {
  name: 'index',
  components: {},
  props: [],
  data () {
    return {
      messageData: [],
      userData: [
        {
          key: "Visitor's Company:",
          value: ''
        },
        {
          key: "Visitor's Full Name:",
          value: ''
        },
        {
          key: "Visitor's Job Title:",
          value: ''
        },
        {
          key: 'Identification Number:',
          value: ''
        },
        {
          key: 'Gate Name:',
          value: ''
        }
      ],
      carData: [
        {
          key: "Visitor's Company:",
          value: ''
        },
        {
          key: 'Plate Number:',
          value: ''
        },
        {
          key: "Visitor's Full Name:",
          value: ''
        },
        {
          key: 'Manufacturer:',
          value: ''
        },
        {
          key: 'Identification Number:',
          value: ''
        },
        {
          key: 'Vehicle Type:',
          value: ''
        },
        {
          key: "Visitor's Job Title:",
          value: ''
        },
        {
          key: "Num of Companions:",
          value: ''
        },
        {
          key: "Gate Name:",
          value: ''
        }
      ],
      tableData: [],
      tableCopyData: [],
      visitorNotesData: [],
      data: {},
      page: 0,
      truncationIndexArr: [], // 截断下标
      surplusHeight: 0, // 内容剩余高度
      filterData: [], // 内容剩余高度
      activePage: 0 // 当前页数
    }
  },
  computed: {
    validityPeriod () {
      const startDate = this.data.start_date?.split('-')?.reverse()?.join('/');
      const endDate = this.data.end_date?.split('-')?.reverse()?.join('/');
      return `${startDate} - ${endDate}` || '-';
    }
  },
  created () {
    this.getData();
    this.$nextTick(() => {
      this.getPageNum(1740, 0);
    })
  },
  methods: {
    getlastPage () {
      if (this.activePage > 0) {
        this.activePage--;
      } else {
        this.activePage = 0;
      }
      this.getTableData();
    },
    getNextPage () {
      if (this.activePage < this.page - 1) {
        this.activePage++;
      }
      this.getTableData();
    },
    getTableData () {
      this.tableData = this.tableCopyData.filter(row => {
        return +row[0].page === +this.activePage;
      })
    },
    getPageNum (allHeight, startIndex) {
      this.truncationIndexArr = [];
      let totalHeight = 0;
      const trElemArr = document.querySelectorAll('tr');
      for (let i = startIndex; i < trElemArr.length; i++) {
        const item = trElemArr[i];
        totalHeight = totalHeight + item.offsetHeight;
        if (totalHeight >= allHeight) {
          this.surplusHeight = totalHeight - allHeight;
          if (this.surplusHeight > 0) {
            // 超过1740 本页舍去该数据
            this.truncationIndexArr.push(i - 1);
            totalHeight = trElemArr[i - 1].offsetHeight + 120;
          } else if (this.surplusHeight === 0) {
            this.truncationIndexArr.push(i);
          }
          continue;
        }
      }
      this.getGrouData();
      this.getTableData();
    },
    getGrouData () {
      if (this.truncationIndexArr[this.truncationIndexArr.length - 1] < this.tableData.length) {
        this.page = this.truncationIndexArr.length + 1;
      }
      for (let i = 0; i < this.truncationIndexArr.length; i++) {
        if (i === 0) {
          this.addPage(0, this.truncationIndexArr[i], i);
        } else {
          this.addPage(this.truncationIndexArr[i - 1], this.truncationIndexArr[i], i);
        }
      }
      const lastStartIndex = this.truncationIndexArr[this.truncationIndexArr.length - 1];
      this.addPage(lastStartIndex, this.tableData.length, this.truncationIndexArr.length);
    },
    addPage (startIndex, endIndex, page) {
      this.tableData.forEach(row => {
        if (+startIndex < +row[0].value && +row[0].value <= +endIndex) {
          row[0].page = page;
        }
      })
      this.tableCopyData.forEach(row => {
        if (+startIndex < +row[0].value && +row[0].value <= +endIndex) {
          row[0].page = page;
        }
      })
    },
    /**
     * @desc: 获取列表
     */
    getData () {
      const dataId = window.location.href.split('?data_id=')?.[1];
      dataInterface({
        object_uuid: 'object660ba01b1525a',
        view_uuid: 'view661f456eebb62',
        __method_name__: 'dataInfo',
        data_id: dataId,
        transcode: 0
      }).then((res) => {
        this.tableData = [];
        this.tableCopyData = [];
        if (res.data.code !== 200) return;
        this.data = res.data.data;
        this.data.accompanied_person_list.forEach((item, index) => {
          const itemArr = [
            {
              key: "index",
              value: index + 1
            },
            {
              key: "Visitor's Full Name",
              value: item.full_name?item.full_name:'-'
            },
            {
              key: "Identification Number",
              value: item.passport?item.passport:'-'
            },
            {
              key: "Mobile Number",
              value: item.phone_number?item.phone_number:'-'
            }
          ]
          this.tableData.push(itemArr)
          this.tableCopyData.push(itemArr)
        })
        console.log(this.tableData, 'this.data')
      })
    },
    getKey (key) {
      switch (key) {
        case "Visitor's Company:":
          return 'Visiting_Company';
        case "Visitor's Full Name:":
          return "full_name";
        case 'Identification Number:':
          return 'passport';
        case 'Company Name:':
          return 'Visiting_Company';
        case "Visitor's Job Title:":
          return "Visiting_Job";
        case 'Gate Name:':
          return 'channel';
        case 'Plate Number:':
          return 'vehicle_number_show';
        case 'Manufacturer:':
          return 'brand_name';
        case 'Vehicle Type:':
          return 'type_name';
        case 'Num of Companions:':
          return 'Visitor_Number';
        case 'License Effective Date:':
          return 'start_date';
        default: '-';
      }
    }
  }
}
</script>
<style lang="less" scoped>
.peer-information-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../img/yellowBackground.png");
  background-position: 100% 100%;
  background-size: 100% 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: revert-layer;
  }
  .frame {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .peer-information-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 364px 420px 0px 413px;
      box-sizing: border-box;
      .logo-id {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 150px;
        .logo-group {
          display: inline-flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 16px;
          width: 508px;
          height: 100%;
          border-radius: 12px;
          .logo-t {
            width: 280px;
            height: 100%;
          }
          .logo-s {
            width: 212px;
            height: 100%;
          }
        }
        .ID {
          flex: 1;
          color: #a47c43;
          font-family: "Microsoft YaHei";
          font-size: 60px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
        }
      }
    }
    .passengers-details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 52px;
      height: 160px;
      margin: 60px 0 40px 0;
      padding: 0 340px;
      box-sizing: border-box;
      img {
        flex: 1;
        height: 7px;
        object-fit: revert-layer;
      }
      .desc {
        color: #a47c43;
        font-family: "Microsoft YaHei";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
      }
    }
    .peer-information-main {
      overflow: hidden;
      width: 100%;
      height: 1740px;
      padding: 0 330px 0 350px;
      box-sizing: border-box;
      table {
        width: 100%;
        height: auto;
        th {
          height: 120px;
          padding: 0 32px;
          box-sizing: border-box;
          border: 1px solid #a47c43;
          background: rgba(162, 122, 52, 0.2);
          color: #a47c43;
          font-family: "Microsoft YaHei";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          white-space: nowrap;
        }
        td {
          min-height: 100px;
          padding: 20px 32px;
          box-sizing: border-box;
          color: #a47c43;
          font-family: "Microsoft YaHei";
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: 1px solid #a47c43;
          text-align: center;
        }
      }
    }
    .change-page {
      display: flex;
      gap: 40px;
      position: absolute;
      bottom: 400px;
      left: 44%;
      height: 200px;
      width: 100px;
      font-size: 50px;
      white-space: nowrap;
      opacity: 0;
    }
    .photo-ID {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 480px;
      height: 480px;
      margin: 60px 406px 448px 1594px;
      background-image: url("../img/QRCode.png");
      background-position: 100% 100%;
      background-size: 100% 100%;
      z-index: 10;
      img {
        width: 420px;
        height: 420px;
        border-radius: 8px;
        object-fit: revert-layer;
      }
    }
  }
}
</style>
